#nf_page{
    .main__flex{
        justify-content center
        padding 0 right bottom left

        .content{
            width 100%
            display flex
            flex-direction column
            align-items center

            .wifi__iframe{
                height 0
                padding-bottom 30%
                width 100%
                max-width 30%
                position relative

                iframe{
                    width 100%
                    height 100%
                    position absolute
                    top 0
                    left 0
                    right 0
                    bottom 0
                    pointer-events none
                    border 0
                }
            }

            a{
                text-decoration none
                color white
                margin-top 2rem

                .nf__text{
                    width 100%
                    display flex
                    flex-direction column
                    align-items center
                    justify-content flex-start

                    h1{
                        color white
                        font-size 1.2rem
                        margin-bottom 2rem
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #nf_page{
        .main__flex{
            .content{
                .wifi__iframe{
                    padding-bottom 65%
                    height 0
                    max-width 60%
                }

                .nf__text{
                    text-align center
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    #nf_page{
        .main__flex{
            .content{
                .wifi__iframe{
                    margin-bottom 2rem
                    padding-bottom 100%
                    max-width 100%
                }

                a{
                    .nf__text{
                        h1{
                            font-size 1rem
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1950px) {
    #nf_page{
        .main__flex{
            .content{
                .wifi__iframe{
                    height 0
                    padding-bottom 50%
                    width 100%
                    max-width 50%
                }

                a{
                    .nf__text{
                        h1{
                            font-size 2rem
                        }
                    }
                }
            }
        }
    }
}