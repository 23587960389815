@import 'layout/*.styl'
@import 'modules/**/*.styl'

@font-face {
    font-family Gotham
    font-style normal
    src url('../../assets/fonts/Gotham-Medium.otf');
}

*{
    font-family Gotham
    -webkit-tap-highlight-color  rgba(255, 255, 255, 0)
}

body{
    width 100%
    background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
    background-color #2F2F2D
    position relative
    min-height 100vh
}

.background{
        width 100%
        height 100%
        max-height 100vh
        position fixed
        top 0
        left 0
        z-index -1
        transition all 0s

        &.bg__primary{
            opacity .5
            animation spin-backwards 300s linear infinite
        }

        &.bg__secondary{
            opacity .3
            transform scale(1.3)
            animation spin 240s linear infinite
        }
    }

.mobile{
    display none
}

@keyframes spin {
    100% {
        transform scale(1.3) rotate(360deg)
    }
}

@keyframes spin-backwards {
    100% {
        transform rotate(-360deg)
    }
}

@keyframes m__spin {
    100% {
        transform scale(1.6) rotate(360deg)
    }
}

@keyframes m__spin-backwards {
    100% {
        transform scale(1.3) rotate(-360deg)
    }
}

@media screen and (max-width: 576px) {

    .background{
        transform scale(3)
        transition .5s all ease

        &.bg__primary{
            transform scale(1.3)
            animation none
        }

        &.bg__secondary{
            transform scale(1.6)
            animation none
        }
    }
    .mobile{
        display block
    }
}