.form-control {
	&::-webkit-input-placeholder {
	}

	&::-moz-placeholder {
	}

	&:-ms-input-placeholder {
	}

	&:-moz-placeholder {
	}

	// hide IE clear and reveal buttons of input fields
	&::-ms-clear, &::-ms-reveal {
		display none
	}
}

/*
 * AUTOCOMPLETE BACKGROUND AND COLOR CHANGE
 */
@keyframes autofill {
	to {
		color $black
		background $white
	}
}

input:-webkit-autofill {
	-webkit-animation-name autofill
	-webkit-animation-fill-mode both
}

/*
 * HIDE NUMBER INPUT SPINNERS
 */
input[type=number] {
	// Firefox:
	-moz-appearance textfield

	// Webkit:
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		-webkit-appearance none
		margin 0
	}
}

/**
 * Custom Radio button
 */
[type='radio']:checked, [type='radio']:not(:checked) {
	position absolute
	left -9999px
}

[type='radio']:checked + label, [type='radio']:not(:checked) + label {
	position relative
	padding-left 28px
	cursor pointer
	line-height 20px
	display inline-block
	color $secondary
}

[type='radio']:checked + label:before, [type='radio']:not(:checked) + label:before {
	content ''
	position absolute
	left 0
	top 0
	width 22px
	height 22px
	border 3px solid $grey
	border-radius 100%
	background #fff
}

[type='radio']:checked + label:after, [type='radio']:not(:checked) + label:after {
	content ''
	width 12px
	height 12px
	background $primary
	position absolute
	top 5px
	left 5px
	border-radius 100%
	-webkit-transition all .2s ease
	transition all .2s ease
}

[type='radio']:not(:checked) + label:after {
	opacity 0
	-webkit-transform scale(0)
	transform scale(0)
}

[type='radio']:checked + label:after {
	opacity 1
	-webkit-transform scale(1)
	transform scale(1)
}

/**
 * END Custom radio button
 */
/**
 * Custom checkbox
 */
[type='checkbox']:not(:checked), [type='checkbox']:checked {
	position absolute
	left -9999px
}

[type='checkbox']:not(:checked) + label, [type='checkbox']:checked + label {
	position relative
	padding-left 28px
	cursor pointer
	line-height 20px
	display inline-block
	color $secondary
}

/* checkbox aspect */
[type='checkbox']:not(:checked) + label:before, [type='checkbox']:checked + label:before {
	content ''
	position absolute
	left 0
	top 0
	width 22px
	height 22px
	border 3px solid $grey
	background $white
	border-radius 4px
	box-shadow inset 0 1px 3px rgba(0, 0, 0, .1)
}

/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after, [type='checkbox']:checked + label:after {
	content '✔'
	position absolute
	top .2em
	left .275em
	font-size 1.4em
	line-height .8
	color $primary
	transition all .2s
	font-family Helvetica, Arial, sans-serif
}

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after {
	opacity 0
	transform scale(0)
}

[type='checkbox']:checked + label:after {
	opacity 1
	transform scale(1)
}

/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label:before, [type='checkbox']:disabled:checked + label:before {
	box-shadow none
	border-color lightgray
	background-color $grey
}

[type='checkbox']:disabled:checked + label:after {
	color lightgray
}

[type='checkbox']:disabled + label {
	color lightgray
}

/* accessibility */
[type='checkbox']:checked:focus + label:before, [type='checkbox']:not(:checked):focus + label:before {
	border 2px dotted blue
}

/* hover style just for information */
label:hover:before {
	border 3px solid blue !important
}

/**
 * END Custom checkbox
 */