/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
	margin 5px 20px 20px 0
}

.aligncenter, div.aligncenter {
	display block
	margin 5px auto
	color $link-hover-color
}

.alignright {
	float right
	margin 5px 0 20px 20px
}

.alignleft {
	float left
	margin 5px 20px 20px 0
}

a {
	img {
		.alignright {
			float right
			margin 5px 0 20px 20px
		}

		.alignnone {
			margin 5px 20px 20px 0
		}

		.alignleft {
			float left
			margin 5px 20px 20px 0
		}

		.aligncenter {
			display block
			margin-left auto
			margin-right auto
		}
	}
}

.wp-caption {
	background $white
	border 1px solid $grey
	max-width 96% /* Image does not overflow the content area */
	padding 5px 3px 10px
	text-align center

	.alignnone {
		margin 5px 20px 20px 0
	}

	.alignleft {
		margin 5px 20px 20px 0
	}

	.alignright {
		margin 5px 0 20px 20px
	}

	img {
		border 0 none
		height auto
		margin 0
		max-width 98.5%
		padding 0
		width auto
	}

	p.wp-caption-text {
		font-size 11px
		line-height 17px
		margin 0
		padding 0 4px 5px
	}
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip rect(1px, 1px, 1px, 1px)
	position absolute !important
	white-space nowrap
	height 1px
	width 1px
	overflow hidden
}

.screen-reader-textfocus {
	background-color $grey
	border-radius 3px
	box-shadow 0 0 2px 2px rgba(0, 0, 0, .6)
	clip auto !important
	color $black
	display block
	font-size 14px
	font-weight bold
	height auto
	left 15px
	line-height normal
	padding 15px 23px 14px
	text-decoration none
	top 5px
	width auto
	z-index 100000 /* Above WP toolbar. */
}
