* {
	outline 0 !important
}

html {
	font-family system-ui
	font-size 15px
}

.p-relative {
	position relative
}

.z-top {
	position relative
	z-index 10
}

.text-underline {
	text-decoration underline
}
