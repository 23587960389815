.heroes{
    position relative

    .background__spheres{
        position fixed
        top 50%
        left 50%
        width 100%
        height 100%
        transform translate(-50%, -50%)
        max-width 1400px
        background-image url('../img/bg_spheres.png')
        background-position center center
        background-size contain
        background-repeat no-repeat
    }

    .heroes__content{
        width 100%
        max-width 1270px
        position relative
        padding 100px 0
        margin 0 auto

        h1{
            font-weight 900
            font-size 96px
            color white
            margin 0 17px 25px
        }

        .excerpt{
            font-weight 400
            max-width 680px
            width 100%
            color white
            line-height 28px
            font-weight 400
            font-size 24px
            margin 0 17px 90px
        }
        
        .heroesFlex{
            width 100%
            display flex
            justify-content flex-start
            align-items flex-start
            flex-wrap wrap
            position relative

            .hero{
                max-width calc(100% / 3 - 35px)
                width 100%
                margin 15px 17px
                position relative
                display flex
                flex-direction column
                justify-content flex-start
                align-items flex-start
                overflow-y visible
                overflow hidden

                &:hover{
                    .hero__picture__clip{
                        .hero__picture{
                            img{
                                transform translateX(-50%) scale(0.8)
                            }

                            .backbox{
                                &:after{
                                    background-position 0 100px
                                }
                            }
                        }
                    }
                }

                .hero__picture__clip{
                    width 100%
                    position relative
                    margin-bottom 20px
                    clip-path polygon(0 0, 100% 0, 100% 100%, 0% 100%)

                    .hero__picture{
                        width 100%
                        position relative
                        padding-top 90px


                        img{
                            object-fit cover
                            position absolute
                            bottom 0
                            left 50%
                            transform translate(-50%, 50%) scale(1.5)
                            z-index 2
                            max-height 560px
                            transition .3s all ease-in-out
                            transform-origin center bottom
                        }

                        .backbox{
                            width 100%
                            height 470px
                            background-color #303030
                            position relative

                            &:after{
                                width 100%
                                height 100%
                                content ''
                                background linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(246,57,0,1) 100%)
                                background-position 0 470px
                                background-repeat no-repeat
                                position absolute
                                bottom 0
                                left 0
                                transition .3s all ease-in-out
                            }
                        }
                    }
                }

                .hero__text{
                    display flex
                    flex-direction column
                    align-items flex-start
                    justify-content flex-start
                    position relative

                    .name{
                        font-weight 900
                        font-size 48px
                        color #F63900
                        margin-bottom 5px
                    }

                    .title{
                        color white
                        font-size 24px
                        line-height 28px
                        margin-bottom 20px
                    }

                    .buttons{
                        display flex
                        position relative

                        .btn{
                            margin-right 20px
                            text-decoration none
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .heroes{
        .heroes__content{

            .excerpt{
                font-size 1rem
                line-height 18px
                margin 0
                padding 0 17px 90px
            }

            .heroesFlex{
                padding 0 17px

                .hero{
                    max-width calc(100% / 2 - 35px)
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .heroes{
        .heroes__content{
            h1{
                font-size 3rem
            }

            .excerpt{
                max-width 50%
            }

            .heroesFlex{
                padding 0
                justify-content center

                .hero{
                    max-width calc(100% - 35px)
                    margin 0 0 60px 0

                    .hero__picture__clip {
                        .hero__picture{
                            img{
                                max-height 350px
                            }

                            .backbox{
                                height 300px
                                max-width 500px
                                margin 0 auto
                            }
                        }
                    }

                    .hero__text{
                        width 100%

                        .name{
                            width 100%
                            text-align center
                        }

                        .title{
                            width 100%
                            text-align center
                        }

                        .buttons{
                            width 100%
                            align-items center
                            justify-content center
                            flex-direction column

                            .btn{
                                margin 0 0 16px
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .heroes{
        .heroes__content{
            .excerpt{
                max-width 100%
            }
            .heroesFlex{
                .hero{
                    .hero__text{
                        width 100%

                        .name{
                            font-size 32px
                        }

                        .title{
                            font-size 20px
                        }
                    }
                }
            }
        }
    }
}