.rotateIcon {
    transform rotate(180deg)
}

.sidebar {
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    z-index 20
    background-color rgba(0, 0, 0, .2)
    transition .3s all
    display none

    &.hidden {
        background-color rgba(0, 0, 0, 0)
        animation animateHide .3s .3s forwards

        .content {
            left -625px
        }
    }

    .content {
        position absolute
        left 0
        top 0
        max-width 625px
        width 100%
        height 100%
        background-color black
        padding 110px 100px
        display flex
        flex-direction column
        justify-content flex-start
        align-items flex-start
        transition .3s all
        z-index 20

        .firstRow {
            display flex
            justify-content space-between
            align-items flex-start
            max-height 85px
            height 100%
            width 100%
            margin-bottom 50px

            i {
                color $primary
                font-size 2rem
                cursor pointer
            }
        }

        ul {
            list-style none

            li {
                margin-bottom 30px

                a, span {
                    font-weight 350
                    color white
                    font-size 36px
                    line-height 42px
                    text-decoration none
                    cursor pointer
                    transition .3s all

                    i {
                        font-size 1.5rem
                        margin-left 10px
                    }

                    &:hover {
                        i {
                            color $primary
                            transition .3s all
                        }

                        color $primary
                    }
                }

                .backstory {
                    font-weight 350
                    font-size 18px
                    line-height 22px
                    color white
                    width 100%
                    text-align left
                    margin-top 10px

                    &.hidden {
                        display none
                    }
                }
            }
        }
    }

    .scroller {
        width 100%
        overflow-y scroll
    }
}

@media screen and (max-width 768px) {
    .sidebar {
        .content {
            padding 2rem
            overflow auto
        }
    }
}