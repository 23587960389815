/**
 * Custom button styles
 */

.btn {
	display inline-block
	text-align center
	white-space nowrap
	vertical-align middle
	user-select none
	border 1px solid transparent
	padding 2rem 3.5rem
	font-size 1rem
	font-family Gotham
	text-transform uppercase
	line-height 1.5
	border-radius 0
	transition color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out

	&.compact{
		padding 16px 20px
		font-size 16px
		font-weight normal
		text-transform none
	}

	&:hover, &:focus {
		text-decoration none
	}

	&:focus, &.focus {
		outline 0
		box-shadow none
	}

	&:disabled, &.disabled {
		opacity .5
		box-shadow none
		pointer-events none
	}

	&:not([disabled]):not(.disabled) {
		cursor pointer
	}

	&.primary{
		background-color $primary
		color white
	}

	&.secondary{
		background-color rgba(0,0,0,0.4)
		color $primary
		border 2px solid $primary
	}
}