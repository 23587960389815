.form-control::-ms-clear,
.form-control::-ms-reveal {
  display: none;
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
input[type=number] {
  -moz-appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fff;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 3px solid #222;
  border-radius: 100%;
  background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #f63900;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}
[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #fff;
}
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 3px solid #222;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
}
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  content: '✔';
  position: absolute;
  top: 0.2em;
  left: 0.275em;
  font-size: 1.4em;
  line-height: 0.8;
  color: #f63900;
  transition: all 0.2s;
  font-family: Helvetica, Arial, sans-serif;
}
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type='checkbox']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
[type='checkbox']:disabled:not(:checked) + label:before,
[type='checkbox']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #d3d3d3;
  background-color: #222;
}
[type='checkbox']:disabled:checked + label:after {
  color: #d3d3d3;
}
[type='checkbox']:disabled + label {
  color: #d3d3d3;
}
[type='checkbox']:checked:focus + label:before,
[type='checkbox']:not(:checked):focus + label:before {
  border: 2px dotted #00f;
}
label:hover:before {
  border: 3px solid #00f !important;
}
@-moz-keyframes autofill {
  to {
    color: #000;
    background: #fff;
  }
}
@-webkit-keyframes autofill {
  to {
    color: #000;
    background: #fff;
  }
}
@-o-keyframes autofill {
  to {
    color: #000;
    background: #fff;
  }
}
@keyframes autofill {
  to {
    color: #000;
    background: #fff;
  }
}
.btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 2rem 3.5rem;
  font-size: 1rem;
  font-family: Gotham;
  text-transform: uppercase;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn.compact {
  padding: 16px 20px;
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled,
.btn.disabled {
  opacity: 0.5;
  box-shadow: none;
  pointer-events: none;
}
.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.btn.primary {
  background-color: #f63900;
  color: #fff;
}
.btn.secondary {
  background-color: rgba(0,0,0,0.4);
  color: #f63900;
  border: 2px solid #f63900;
}
* {
  outline: 0 !important;
}
html {
  font-family: system-ui;
  font-size: 15px;
}
.p-relative {
  position: relative;
}
.z-top {
  position: relative;
  z-index: 10;
}
.text-underline {
  text-decoration: underline;
}
.alignnone {
  margin: 5px 20px 20px 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto;
  color: #ff531f;
}
.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img .alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
a img .alignnone {
  margin: 5px 20px 20px 0;
}
a img .alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption {
  background: #fff;
  border: 1px solid #222;
  max-width: 96% /* Image does not overflow the content area */;
  padding: 5px 3px 10px;
  text-align: center;
}
.wp-caption .alignnone {
  margin: 5px 20px 20px 0;
}
.wp-caption .alignleft {
  margin: 5px 20px 20px 0;
}
.wp-caption .alignright {
  margin: 5px 0 20px 20px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.screen-reader-textfocus {
  background-color: #222;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.6);
  clip: auto !important;
  color: #000;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  left: 15px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000 /* Above WP toolbar. */;
}
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat;
}
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../fonts/slick/slick.eot");
  src: url("../fonts/slick/slick.eot?#iefix") format('embedded-opentype'), url("../fonts/slick/slick.woff") format('woff'), url("../fonts/slick/slick.ttf") format('truetype'), url("../fonts/slick/slick.svg#slick") format('svg');
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: transparent;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  color: transparent;
  outline: 0;
  background: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: 0;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}
.slick-track,
.slick-list {
  perspective: 2000;
  backface-visibility: hidden;
}
.navbar {
  background-color: #fff;
}
.navbar .navbar-collapse .nav-item .nav-link {
  color: #f63900;
  font-size: responsive 14px 18px;
  font-range: 320px 1200px;
}
#nf_page .main__flex {
  justify-content: center;
  padding: 0 right bottom left;
}
#nf_page .main__flex .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#nf_page .main__flex .content .wifi__iframe {
  height: 0;
  padding-bottom: 30%;
  width: 100%;
  max-width: 30%;
  position: relative;
}
#nf_page .main__flex .content .wifi__iframe iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border: 0;
}
#nf_page .main__flex .content a {
  text-decoration: none;
  color: #fff;
  margin-top: 2rem;
}
#nf_page .main__flex .content a .nf__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#nf_page .main__flex .content a .nf__text h1 {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 768px) {
  #nf_page .main__flex .content .wifi__iframe {
    padding-bottom: 65%;
    height: 0;
    max-width: 60%;
  }
  #nf_page .main__flex .content .nf__text {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  #nf_page .main__flex .content .wifi__iframe {
    margin-bottom: 2rem;
    padding-bottom: 100%;
    max-width: 100%;
  }
  #nf_page .main__flex .content a .nf__text h1 {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1950px) {
  #nf_page .main__flex .content .wifi__iframe {
    height: 0;
    padding-bottom: 50%;
    width: 100%;
    max-width: 50%;
  }
  #nf_page .main__flex .content a .nf__text h1 {
    font-size: 2rem;
  }
}
.episode {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.episode #frame__next {
  position: fixed;
  left: 50%;
  z-index: 99;
  color: rgba(255,255,255,0.3);
  background-color: rgba(0,0,0,0.3);
  border: 2px solid rgba(255,255,255,0.3);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  transform: translate(-50%, -50px);
  cursor: pointer;
  transition: 0.3s all;
  animation: animateArrow 2s cubic-bezier(0.42, 0.01, 0.99, 0.65) infinite;
}
.episode #frame__next:hover {
  background-color: #000;
  color: #fff;
  border: 2px solid #fff;
  animation: none;
}
.episode #episode__navigator {
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translatex(-49.25%);
  z-index: 5;
  color: #fff;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
}
.episode #episode__navigator.hidden {
  display: none;
}
.episode #episode__navigator a {
  color: #fff;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.episode #episode__navigator a.disabled {
  color: #808080;
  pointer-events: none;
  cursor: none;
}
.episode #episode__navigator a:hover .nav {
  color: #f63900;
}
.episode #episode__navigator a .nav {
  font-size: 2rem;
  transition: 0.3s color;
  margin: 0 1rem;
  transform: translateY(29%);
}
.episode #episode__navigator #rewatch {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  margin: 0 2rem;
}
.episode #episode__navigator #rewatch:hover .re {
  color: #f63900;
}
.episode #episode__navigator #rewatch .re {
  font-size: 2rem;
  margin: 0 1rem 0.5rem;
  animation: rotateReArrow 8s infinite;
  cursor: pointer;
  transition: 0.3s color;
}
.episode #frame__next {
  bottom: 0;
}
.episode .placeholder {
  display: none;
  width: 100%;
}
.episode .pageAfter {
  width: 100%;
  padding-bottom: 30rem;
}
.episode .pin {
  width: 100%;
  height: 2px;
  background-color: #ff0;
  z-index: 2;
}
.episode .logos {
  position: fixed;
  left: 3.5rem;
  top: 3rem;
  max-height: 3.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  z-index: 3;
}
.episode .logos .logo__background {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.episode .logos .logo__background img {
  max-height: 3.75rem;
  max-width: 100%;
  width: auto;
  margin-right: 2rem;
}
.episode .logos .logo__background i {
  cursor: pointer;
  font-size: 2rem;
  color: #f63900;
}
.episode .buttons {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 4rem 4rem 2rem 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: linear-gradient(0deg, #000 0%, #000 25%, rgba(47,47,45,0) 90%);
}
.episode .buttons >div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.episode .buttons >div .hidden {
  display: none;
}
.episode .buttons >div button {
  padding: 1rem;
  font-weight: 500;
}
.episode .buttons .poweredby {
  width: 100%;
  margin-top: 2rem;
  color: #4b4b4b;
  font-weight: 500;
}
.episode .buttons .poweredby a {
  color: inherit;
  text-decoration: none;
}
.episode #picker__toggle {
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(-90deg) translateY(40px);
  width: 110px;
  background-color: rgba(0,0,0,0.5);
  border: 1px solid rgba(255,255,255,0.5);
  color: rgba(255,255,255,0.5);
  text-align: center;
  border-bottom: none;
  height: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  backdrop-filter: blur(4px);
}
.episode #picker__toggle i {
  margin-left: 10px;
}
.episode #picker {
  display: none;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 9rem;
  background-color: rgba(0,0,0,0.5);
  border: 1px solid #fff;
  padding: 2rem;
  color: #fff;
  text-align: center;
  border-right: none;
  backdrop-filter: blur(4px);
}
.episode #picker .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.episode #picker .close i {
  display: block;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s color;
}
.episode #picker .close i:hover {
  color: #f63900;
}
.episode #picker i {
  display: none;
}
.episode #picker .picker__carousel a {
  color: #fff;
  text-decoration: none;
}
.episode #picker .picker__carousel a.active {
  color: #f63900;
  pointer-events: none;
  cursor: none;
}
.episode #picker .picker__carousel a .item {
  margin: 1.5rem 0;
}
.episode .frames {
  padding: 1rem;
  width: 100%;
  max-width: calc(1720px + 2rem);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  top: 15vh;
}
.episode .frames .trigger {
  width: 100%;
  height: 5px;
  margin: 1rem 0;
  background-color: #008000;
}
.episode .frames .row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
}
.episode .frames .row .frame {
  opacity: 0;
  height: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-bottom: 34%;
  background-position: center center;
  background-size: cover;
  background-repeat: norepeat;
  position: relative;
}
.episode .frames .row .frame.f1-1 {
  max-width: 100%;
  width: 100%;
}
.episode .frames .row .frame.f3-1 {
  max-width: calc(100% / 3 - 1rem);
  width: 100%;
  padding-bottom: 33%;
}
.episode .frames .row .frame.f3-1:first-of-type {
  margin-right: 1.5rem;
}
.episode .frames .row .frame.f3-1:last-of-type {
  margin-left: 1.5rem;
}
.episode .frames .row .frame.f3-2 {
  padding-bottom: 33%;
}
.episode .frames .row .frame.f3-2.f0 {
  max-width: calc((100% / 3) * 1 - 0.5rem);
  width: 100%;
}
.episode .frames .row .frame.f3-2.f0.f3-2_reverse {
  max-width: calc((100% / 3) * 2 - 0.5rem);
}
.episode .frames .row .frame.f3-2.f1 {
  max-width: calc((100% / 3) * 2 - 0.5rem);
  width: 100%;
}
.episode .frames .row .frame.f3-2.f1.f3-2_reverse {
  max-width: calc((100% / 3) * 1 - 0.5rem);
}
.episode .frames .row .frame.f3-2:last-child {
  margin-left: 1rem;
}
.episode .frames .row .frame.f2-2 {
  padding-bottom: 33%;
  max-width: calc(100% / 2 - 1rem);
  width: 100%;
}
.episode .frames .row .frame.f2-2:first-of-type {
  margin-right: 1rem;
}
.episode .frames .row .frame.f2-2:last-of-type {
  margin-left: 1rem;
}
.episode .frames .row .frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  pointer-events: none;
  object-fit: fill;
}
.episode .frames .row .frame img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .episode .frames {
    top: 25vh;
  }
}
@media screen and (max-width: 576px) {
  .episode {
    padding-bottom: 0.5rem;
  }
  .episode #episode__navigator {
    bottom: 4rem;
    transform: translatex(-50%);
  }
  .episode .background {
    transform: scale(4.5);
  }
  .episode .pageAfter {
    padding-bottom: 25rem;
  }
  .episode .logos {
    top: 0;
    left: 0;
    padding: 1rem 1rem 7rem 1rem;
    width: 100%;
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.499) 50%, #000 100%);
  }
  .episode .logos .logo__background {
    width: 100%;
  }
  .episode .logos .logo__background img {
    margin-right: 0.5rem;
  }
  .episode .logos .logo__background a {
    margin-left: auto;
  }
  .episode .logos .logo__background a button {
    padding: 1rem 0.5rem;
    margin: 0 !important;
  }
  .episode .logos .logo__background i {
    margin-left: auto;
  }
  .episode .frames {
    padding: 0.5rem;
    top: 40vh;
  }
  .episode .frames .row {
    margin-bottom: 0;
  }
  .episode .frames .row .frame {
    padding-bottom: 33%;
    margin-bottom: 1rem;
  }
  .episode .frames .row .frame.f1-1 {
    margin-bottom: 0.75rem !important;
  }
  .episode .frames .row .frame.f3-1 {
    max-width: 100%;
    padding-bottom: 100%;
    margin: 0 0 1rem 0 !important;
  }
  .episode .frames .row .frame.f3-2.f0 {
    margin-left: 0;
    max-width: 100%;
    padding-bottom: 100%;
  }
  .episode .frames .row .frame.f3-2.f0.f3-2_reverse {
    margin-left: 0;
    max-width: 100%;
    padding-bottom: 50%;
  }
  .episode .frames .row .frame.f3-2.f1 {
    max-width: 100%;
    padding-bottom: 50%;
    margin-left: 0 !important;
  }
  .episode .frames .row .frame.f3-2.f1.f3-2_reverse {
    margin-left: 0;
    max-width: 100%;
    padding-bottom: 100%;
  }
  .episode .frames .row .frame.f2-2 {
    max-width: 100%;
    padding-bottom: 66.66%;
    margin: 0 0 1rem 0 !important;
  }
  .episode .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 1rem;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, #000 0%, rgba(0,0,0,0.499) 50%, rgba(255,255,255,0) 100%);
    padding-top: 7rem;
  }
  .episode .buttons >a {
    display: none;
  }
  .episode .buttons .poweredby {
    width: 100%;
    text-align: center;
    padding: 0 1rem;
  }
}
@-moz-keyframes rotateReArrow {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-360deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes rotateReArrow {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-360deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-o-keyframes rotateReArrow {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-360deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotateReArrow {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-360deg);
  }
  50% {
    transform: rotate(-360deg);
  }
  75% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes animateArrow {
  0% {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
  50% {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }
  100 {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
}
@-webkit-keyframes animateArrow {
  0% {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
  50% {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }
  100 {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
}
@-o-keyframes animateArrow {
  0% {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
  50% {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }
  100 {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
}
@keyframes animateArrow {
  0% {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
  50% {
    color: #fff;
    background-color: #000;
    border: 2px solid #fff;
  }
  100 {
    color: rgba(255,255,255,0.3);
    background-color: rgba(0,0,0,0.3);
    border: 2px solid rgba(255,255,255,0.3);
  }
}
.dcuheader {
  width: 100%;
  position: relative;
  height: 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 45px;
}
.dcuheader a {
  height: 100%;
}
.dcuheader .logo {
  height: 100%;
}
.dcuheader .logo__dcu {
  margin-right: 40px;
}
.dcuheader .logo__dp {
  margin-right: 30px;
}
.dcuheader i {
  cursor: pointer;
  font-size: 2rem;
  color: #f63900;
}
@media screen and (max-width: 768px) {
  .dcuheader {
    padding: 40px 17px;
  }
  .dcuheader .logo__dcu,
  .dcuheader .logo__dp {
    margin-right: 20px;
  }
  .dcuheader i {
    margin-left: auto;
  }
}
.heroes {
  position: relative;
}
.heroes .background__spheres {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  max-width: 1400px;
  background-image: url("../img/bg_spheres.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.heroes .heroes__content {
  width: 100%;
  max-width: 1270px;
  position: relative;
  padding: 100px 0;
  margin: 0 auto;
}
.heroes .heroes__content h1 {
  font-weight: 900;
  font-size: 96px;
  color: #fff;
  margin: 0 17px 25px;
}
.heroes .heroes__content .excerpt {
  font-weight: 400;
  max-width: 680px;
  width: 100%;
  color: #fff;
  line-height: 28px;
  font-weight: 400;
  font-size: 24px;
  margin: 0 17px 90px;
}
.heroes .heroes__content .heroesFlex {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}
.heroes .heroes__content .heroesFlex .hero {
  max-width: calc(100% / 3 - 35px);
  width: 100%;
  margin: 15px 17px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: visible;
  overflow: hidden;
}
.heroes .heroes__content .heroesFlex .hero:hover .hero__picture__clip .hero__picture img {
  transform: translateX(-50%) scale(0.8);
}
.heroes .heroes__content .heroesFlex .hero:hover .hero__picture__clip .hero__picture .backbox:after {
  background-position: 0 100px;
}
.heroes .heroes__content .heroesFlex .hero .hero__picture__clip {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture {
  width: 100%;
  position: relative;
  padding-top: 90px;
}
.heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture img {
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%) scale(1.5);
  z-index: 2;
  max-height: 560px;
  transition: 0.3s all ease-in-out;
  transform-origin: center bottom;
}
.heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture .backbox {
  width: 100%;
  height: 470px;
  background-color: #303030;
  position: relative;
}
.heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture .backbox:after {
  width: 100%;
  height: 100%;
  content: '';
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, #f63900 100%);
  background-position: 0 470px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.3s all ease-in-out;
}
.heroes .heroes__content .heroesFlex .hero .hero__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.heroes .heroes__content .heroesFlex .hero .hero__text .name {
  font-weight: 900;
  font-size: 48px;
  color: #f63900;
  margin-bottom: 5px;
}
.heroes .heroes__content .heroesFlex .hero .hero__text .title {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
}
.heroes .heroes__content .heroesFlex .hero .hero__text .buttons {
  display: flex;
  position: relative;
}
.heroes .heroes__content .heroesFlex .hero .hero__text .buttons .btn {
  margin-right: 20px;
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .heroes .heroes__content .excerpt {
    font-size: 1rem;
    line-height: 18px;
    margin: 0;
    padding: 0 17px 90px;
  }
  .heroes .heroes__content .heroesFlex {
    padding: 0 17px;
  }
  .heroes .heroes__content .heroesFlex .hero {
    max-width: calc(100% / 2 - 35px);
  }
}
@media screen and (max-width: 992px) {
  .heroes .heroes__content h1 {
    font-size: 3rem;
  }
  .heroes .heroes__content .excerpt {
    max-width: 50%;
  }
  .heroes .heroes__content .heroesFlex {
    padding: 0;
    justify-content: center;
  }
  .heroes .heroes__content .heroesFlex .hero {
    max-width: calc(100% - 35px);
    margin: 0 0 60px 0;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture img {
    max-height: 350px;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__picture__clip .hero__picture .backbox {
    height: 300px;
    max-width: 500px;
    margin: 0 auto;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text {
    width: 100%;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .name {
    width: 100%;
    text-align: center;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .title {
    width: 100%;
    text-align: center;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .buttons {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .buttons .btn {
    margin: 0 0 16px;
  }
}
@media screen and (max-width: 768px) {
  .heroes .heroes__content .excerpt {
    max-width: 100%;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text {
    width: 100%;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .name {
    font-size: 32px;
  }
  .heroes .heroes__content .heroesFlex .hero .hero__text .title {
    font-size: 20px;
  }
}
.main {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.main .animateIn {
  opacity: 0;
}
.main #three-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 22;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #000;
}
.main #three-container video {
  width: 100%;
  height: 100%;
}
.main .logoAnimBackground {
  height: 100vh;
  width: 100%;
  opacity: 1;
  background-color: rgba(0,0,0,0.7);
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
}
.main img {
  user-select: none;
}
.main .characters {
  width: 80%;
  max-width: 1250px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
}
.main .foni__zsombor {
  width: 100%;
  max-width: 1100px;
}
.main .characters__wifi {
  width: 80%;
  max-width: 1250px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  z-index: 2;
}
.main .characters__wifi .wifi {
  width: 100%;
  max-width: 1100px;
}
.main .main__flex {
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  height: 100%;
  min-height: 100vh;
  padding: 2rem 1rem 0;
  width: 100%;
  max-width: 1600px;
  position: relative;
  overflow: hidden;
}
.main .main__flex .mobile__flex {
  width: 100%;
  position: relative;
}
.main .triggermenu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.main .triggermenu .dculogo {
  margin-bottom: 2.5rem;
  z-index: 10;
  padding-left: 4rem;
}
.main .triggermenu .sidebarButton {
  color: #f63900;
  font-size: 2rem;
  margin-left: 40px;
  cursor: pointer;
  z-index: 5;
}
.main .dplogo {
  width: 50%;
  padding-left: 3rem;
  z-index: 2;
}
.main .dplogo h1 {
  height: 0;
  width: 0;
  font-size: 0;
}
.main .dplogo img {
  max-height: 20rem;
  width: 100%;
}
.main .backgradient {
  display: none;
}
.main .buttons__flex {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 2rem;
  z-index: 5;
}
.main .buttons__flex .buttons {
  display: flex;
  flex-wrap: wrap;
  max-width: 18.5rem;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  position: relative;
  padding-bottom: 3rem;
  flex-grow: 1;
}
.main .buttons__flex .buttons>a {
  margin-bottom: 1.25rem;
  font-weight: 500;
}
.main .buttons__flex .buttons .latest {
  width: 18.5rem;
}
.main .buttons__flex .buttons .buttons__episode {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.main .buttons__flex .buttons .buttons__episode .select2 {
  width: 50% !important;
  transition: 0.3s background-color;
}
.main .buttons__flex .buttons .buttons__episode .select2:hover {
  background-color: #000;
}
.main .buttons__flex .buttons .buttons__episode .select2 .select2-selection {
  height: 3.5rem;
  display: flex;
  align-items: center;
}
.main .buttons__flex .buttons .buttons__episode .select2 .select2-selection .select2-selection__rendered {
  width: 100%;
  height: 3.5rem;
  padding-left: 1rem;
}
.main .buttons__flex .buttons .buttons__episode .select2 .select2-selection .select2-selection__arrow {
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}
.main .buttons__flex .buttons .buttons__episode button {
  width: 50%;
  background-color: transparent;
  color: #fff;
  padding: 1rem 1.25rem;
  border: 1px solid #fff;
  border-left: none;
  height: 3.5rem;
  cursor: pointer;
  transition: 0.3s background-color;
}
.main .buttons__flex .buttons .buttons__episode button:hover {
  background-color: #000;
}
.main .buttons__flex .buttons .language__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.main .buttons__flex .buttons .language__wrapper .buttons__language {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  border: 1px solid #fff;
}
.main .buttons__flex .buttons .language__wrapper .buttons__language a {
  padding: 4px 6px;
  color: rgba(255,255,255,0.5);
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #000;
  transition: 0.3s background-color;
  border: 0;
  text-decoration: none;
}
.main .buttons__flex .buttons .language__wrapper .buttons__language a.selected {
  background-color: #f63900;
  color: #fff;
}
.main .poweredby {
  color: #4b4b4b;
  font-weight: 500;
  justify-self: flex-end;
  margin-bottom: 2rem;
  width: 100%;
}
.main .poweredby a {
  color: inherit;
  text-decoration: none;
}
@media screen and (min-width: 2000px) {
  .main .characters {
    max-width: unset;
  }
  .main .characters img {
    max-width: unset;
  }
  .main .characters__wifi {
    max-width: unset;
  }
  .main .characters__wifi img {
    max-width: unset;
  }
}
@media screen and (max-width: 576px) {
  .main .main__flex {
    padding: 2rem;
  }
  .main .main__flex .mobile__flex {
    display: flex;
    z-index: 10;
  }
  .main .main__flex .dculogo {
    max-width: 5.5rem;
    margin-bottom: 2rem;
    padding-left: 0;
  }
  .main .main__flex .dculogo svg {
    margin-left: 0;
    max-height: 4rem;
  }
  .main .main__flex .characters {
    height: 100%;
    width: 100%;
    max-width: 100%;
    z-index: 2;
  }
  .main .main__flex .characters .foni__zsombor {
    max-height: 30rem;
    position: absolute;
    right: 50%;
    transform: translatex(40%);
    width: unset;
    max-width: unset;
  }
  .main .main__flex .characters__wifi {
    height: 100%;
    width: 100%;
    max-width: 100%;
    z-index: 3;
  }
  .main .main__flex .characters__wifi .wifi {
    max-height: 12rem;
    position: absolute;
    right: 50%;
    bottom: 8rem;
    transform: translatex(40%);
    width: unset;
    max-width: unset;
  }
  .main .main__flex .dplogo {
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
  }
  .main .main__flex .dplogo img {
    max-height: 4rem;
    width: unset;
  }
  .main .main__flex .buttons__flex {
    justify-content: center;
    z-index: 5;
  }
  .main .main__flex .buttons__flex .buttons {
    flex-grow: 1;
    align-content: flex-end;
    flex-direction: row;
    margin: 0;
    width: 100%;
    max-width: unset;
    justify-content: space-between;
    padding-bottom: 0;
  }
  .main .main__flex .buttons__flex .buttons>a {
    width: calc(50% - 1rem);
    order: 2;
    margin-bottom: 0;
  }
  .main .main__flex .buttons__flex .buttons>a button {
    width: 100%;
    margin-bottom: 0;
    padding: 0 0.4rem;
    white-space: break-spaces;
    height: 5rem;
  }
  .main .main__flex .buttons__flex .buttons .buttons__episode {
    margin-bottom: 0;
    width: 50%;
    order: 1;
  }
  .main .main__flex .buttons__flex .buttons .buttons__episode .select2 {
    width: 100% !important;
  }
  .main .main__flex .buttons__flex .buttons .buttons__episode .select2 .select2-selection {
    height: 2.5rem;
  }
  .main .main__flex .buttons__flex .buttons .buttons__episode .select2 .select2-selection .select2-selection__rendered {
    height: 2.5rem;
  }
  .main .main__flex .buttons__flex .buttons .buttons__episode button {
    width: 100%;
    border-left: 1px solid #fff;
    border-top: 1px;
    padding: 0 1.25rem;
    height: 2.5rem;
  }
  .main .main__flex .buttons__flex .buttons .poweredby {
    order: 3;
    width: 100%;
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 0;
  }
  .main .backgradient {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 20rem;
    background: linear-gradient(0, #000 0, #000 45%, rgba(47,47,45,0) 100%);
    z-index: 4;
  }
}
.select2-dropdown {
  max-height: 10.5rem;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #fff;
}
@-moz-keyframes logoAnim {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes logoAnim {
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes logoAnim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes logoAnim {
  to {
    stroke-dashoffset: 0;
  }
}
#episodeSelect {
  display: none;
}
.selection .select2-selection {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  height: 3rem;
}
.selection .select2-selection .select2-selection__rendered {
  color: #fff;
  height: 3rem;
  display: flex;
  align-items: center;
}
.selection .select2-selection .select2-selection__arrow {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  height: 3rem;
}
.select2-container--default.select2-container--disabled .select2-selection__arrow {
  display: none;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: transparent;
}
.select2-container .select2-dropdown {
  background-color: rgba(0,0,0,0.8);
  border: none;
  border-radius: 0;
}
.select2-container .select2-dropdown .select2-search {
  display: none;
}
.select2-container .select2-dropdown .select2-results__options {
  max-height: 10.5rem;
}
.select2-container .select2-dropdown .select2-results__options .select2-results__option {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-bottom: none;
  border-top: none;
  border-radius: 0;
  transition: 0.3s all;
  height: 3.5rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  backdrop-filter: blur(2px);
}
.select2-container .select2-dropdown .select2-results__options .select2-results__option:first-of-type {
  border-top: 1px solid #fff;
}
.select2-container .select2-dropdown .select2-results__options .select2-results__option:hover {
  background-color: #000;
}
@media screen and (max-width: 576px) {
  .selection .select2-selection {
    height: 1.75rem;
  }
  .selection .select2-selection .select2-selection__rendered {
    height: 1.75rem;
  }
  .selection .select2-selection .select2-selection__arrow {
    height: 1.75rem;
  }
}
.rotateIcon {
  transform: rotate(180deg);
}
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(0,0,0,0.2);
  transition: 0.3s all;
  display: none;
}
.sidebar.hidden {
  background-color: rgba(0,0,0,0);
  animation: animateHide 0.3s 0.3s forwards;
}
.sidebar.hidden .content {
  left: -625px;
}
.sidebar .content {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 625px;
  width: 100%;
  height: 100%;
  background-color: #000;
  padding: 110px 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.3s all;
  z-index: 20;
}
.sidebar .content .firstRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 85px;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}
.sidebar .content .firstRow i {
  color: #f63900;
  font-size: 2rem;
  cursor: pointer;
}
.sidebar .content ul {
  list-style: none;
}
.sidebar .content ul li {
  margin-bottom: 30px;
}
.sidebar .content ul li a,
.sidebar .content ul li span {
  font-weight: 350;
  color: #fff;
  font-size: 36px;
  line-height: 42px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s all;
}
.sidebar .content ul li a i,
.sidebar .content ul li span i {
  font-size: 1.5rem;
  margin-left: 10px;
}
.sidebar .content ul li a:hover,
.sidebar .content ul li span:hover {
  color: #f63900;
}
.sidebar .content ul li a:hover i,
.sidebar .content ul li span:hover i {
  color: #f63900;
  transition: 0.3s all;
}
.sidebar .content ul li .backstory {
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  width: 100%;
  text-align: left;
  margin-top: 10px;
}
.sidebar .content ul li .backstory.hidden {
  display: none;
}
.sidebar .scroller {
  width: 100%;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  .sidebar .content {
    padding: 2rem;
    overflow: auto;
  }
}
.singleHero {
  position: relative;
  width: 100%;
}
.singleHero .background__spheres {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  max-width: 1400px;
  background-image: url("../img/bg_spheres.png");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.singleHero .heroFlex {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 2rem;
}
.singleHero .heroFlex .hero__info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 0 0 175px;
  position: relative;
}
.singleHero .heroFlex .hero__info h1 {
  font-weight: 900;
  color: #f63900;
  font-size: 96px;
  line-height: 115px;
  margin-bottom: 8px;
}
.singleHero .heroFlex .hero__info h2 {
  font-weight: 400;
  font-size: 36px;
  line-height: 42px;
  color: #fff;
  margin-bottom: 42px;
}
.singleHero .heroFlex .hero__info p {
  max-width: 610px;
  text-align: left;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 50px;
  max-height: 150px;
  overflow: auto;
  padding-right: 6px;
}
.singleHero .heroFlex .hero__info ul {
  list-style: none;
  margin-bottom: 15px;
}
.singleHero .heroFlex .hero__info ul li {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}
.singleHero .heroFlex .hero__info ul li i {
  margin-right: 36px;
  color: #f63900;
  font-size: 1.5rem;
  width: 20px;
}
.singleHero .heroFlex .hero__info ul li span {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 900;
}
.singleHero .heroFlex .hero__info .buttons {
  display: flex;
}
.singleHero .heroFlex .hero__info .buttons a {
  margin-right: 20px;
  text-decoration: none;
}
.singleHero .heroFlex .hero__image {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px 175px 100px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 1200px) {
  .singleHero .heroFlex .hero__info {
    padding: 16px 0 0 45px;
  }
  .singleHero .heroFlex .hero__info h1 {
    font-size: 64px;
    line-height: 80px;
  }
  .singleHero .heroFlex .hero__info h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .singleHero .heroFlex .hero__image {
    padding: 16px 45px 0 0;
  }
}
@media screen and (max-width: 992px) {
  .singleHero .heroFlex .hero__info {
    width: 100%;
    order: 2;
    padding-bottom: 5rem;
  }
  .singleHero .heroFlex .hero__image {
    width: 100%;
    order: 1;
    height: 25rem;
  }
}
@media screen and (max-width: 768px) {
  .singleHero .heroFlex .hero__info {
    padding: 0;
    margin: 16px 16px 0;
  }
  .singleHero .heroFlex .hero__info p {
    width: 100%;
    max-width: unset;
  }
  .singleHero .heroFlex .hero__image {
    width: 100%;
    order: 1;
    height: 25rem;
  }
  .singleHero .heroFlex .buttons {
    margin-bottom: 5rem;
    width: 100%;
    justify-content: space-between;
  }
}
@media screen and (max-width: 576px) {
  .singleHero .heroFlex .buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .singleHero .heroFlex .buttons .btn {
    margin-bottom: 16px;
  }
}
@font-face {
  font-family: Gotham;
  font-style: normal;
  src: url("../../assets/fonts/Gotham-Medium.otf");
}
* {
  font-family: Gotham;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}
body {
  width: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000 100%);
  background-color: #2f2f2d;
  position: relative;
  min-height: 100vh;
}
.background {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0s;
}
.background.bg__primary {
  opacity: 0.5;
  animation: spin-backwards 300s linear infinite;
}
.background.bg__secondary {
  opacity: 0.3;
  transform: scale(1.3);
  animation: spin 240s linear infinite;
}
.mobile {
  display: none;
}
@media screen and (max-width: 576px) {
  .background {
    transform: scale(3);
    transition: 0.5s all ease;
  }
  .background.bg__primary {
    transform: scale(1.3);
    animation: none;
  }
  .background.bg__secondary {
    transform: scale(1.6);
    animation: none;
  }
  .mobile {
    display: block;
  }
}
@-moz-keyframes spin {
  100% {
    transform: scale(1.3) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    transform: scale(1.3) rotate(360deg);
  }
}
@-o-keyframes spin {
  100% {
    transform: scale(1.3) rotate(360deg);
  }
}
@keyframes spin {
  100% {
    transform: scale(1.3) rotate(360deg);
  }
}
@-moz-keyframes spin-backwards {
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin-backwards {
  100% {
    transform: rotate(-360deg);
  }
}
@-o-keyframes spin-backwards {
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes spin-backwards {
  100% {
    transform: rotate(-360deg);
  }
}
@-moz-keyframes m__spin {
  100% {
    transform: scale(1.6) rotate(360deg);
  }
}
@-webkit-keyframes m__spin {
  100% {
    transform: scale(1.6) rotate(360deg);
  }
}
@-o-keyframes m__spin {
  100% {
    transform: scale(1.6) rotate(360deg);
  }
}
@keyframes m__spin {
  100% {
    transform: scale(1.6) rotate(360deg);
  }
}
@-moz-keyframes m__spin-backwards {
  100% {
    transform: scale(1.3) rotate(-360deg);
  }
}
@-webkit-keyframes m__spin-backwards {
  100% {
    transform: scale(1.3) rotate(-360deg);
  }
}
@-o-keyframes m__spin-backwards {
  100% {
    transform: scale(1.3) rotate(-360deg);
  }
}
@keyframes m__spin-backwards {
  100% {
    transform: scale(1.3) rotate(-360deg);
  }
}
