.main {
    display flex
    width 100%
    height 100%
    min-height 100vh
    position relative
    flex-wrap wrap
    justify-content center
    align-items center

    .animateIn {
        opacity 0
    }

    #three-container {
        position absolute
        left 0
        top 0
        z-index 22
        overflow hidden
        width 100%
        height 100vh
        background-color black

        video {
            width 100%
            height 100%
        }
    }

    .logoAnimBackground {
        height 100vh
        width 100%
        opacity 1
        background-color rgba(0, 0, 0, .7)
        z-index 9
        position fixed
        top 0
        left 0
    }

    img {
        user-select none
    }

    .characters {
        width 80%
        max-width 1250px
        position absolute
        bottom 0
        right 0
        height 100%
        display flex
        justify-content flex-end
        align-items flex-end
        z-index 1
    }

    .foni__zsombor {
        width 100%
        max-width 1100px
    }

    .characters__wifi {
        width 80%
        max-width 1250px
        position absolute
        bottom 0
        right 0
        height 100%
        display flex
        justify-content flex-start
        align-items flex-end
        z-index 2

        .wifi {
            width 100%
            max-width 1100px
        }
    }

    .main__flex {
        z-index 2
        display flex
        flex-wrap wrap
        flex-direction column
        justify-content flex-start
        align-content flex-start
        height 100%
        min-height 100vh
        padding 2rem 1rem 0
        width 100%
        max-width 1600px
        position relative
        overflow hidden

        .mobile__flex {
            width 100%
            position relative
        }
    }

    .triggermenu {
        width 100%
        display flex
        justify-content flex-start
        align-items flex-start

        .dculogo {
            margin-bottom 2.5rem
            z-index 10
            padding-left 4rem
        }

        .sidebarButton {
            color $primary
            font-size 2rem
            margin-left 40px
            cursor pointer
            z-index: 5
        }
    }

    .dplogo {
        width 50%
        padding-left 3rem
        z-index 2

        h1 {
            height 0
            width 0
            font-size 0
        }

        img {
            max-height 20rem
            width 100%
        }
    }

    .backgradient {
        display none
    }

    .buttons__flex {
        display flex
        flex-grow 1
        align-items flex-end
        justify-content flex-start
        flex-wrap wrap
        padding-top 2rem
        z-index 5

        .buttons {
            display flex
            flex-wrap wrap
            max-width 18.5rem
            align-items center
            justify-content center
            margin-left 2rem
            position relative
            padding-bottom 3rem
            flex-grow 1

            &>a {
                margin-bottom 1.25rem
                font-weight 500
            }

            .latest {
                width: 18.5rem
            }
            
            .buttons__episode {
                width 100%
                display flex
                flex-wrap wrap
                margin-bottom 1.25rem

                .select2 {
                    width 50% !important
                    transition .3s background-color

                    &:hover {
                        background-color black
                    }

                    .select2-selection {
                        height 3.5rem
                        display flex
                        align-items center

                        .select2-selection__rendered {
                            width 100%
                            height 3.5rem
                            padding-left 1rem
                        }

                        .select2-selection__arrow {
                            top 50%
                            transform translateY(-50%) rotate(180deg)
                        }
                    }
                }

                button {
                    width 50%
                    background-color transparent
                    color white
                    padding 1rem 1.25rem
                    border 1px solid white
                    border-left none
                    height 3.5rem
                    cursor pointer
                    transition .3s background-color

                    &:hover {
                        background-color black
                    }
                }
            }

            .language__wrapper {
                width 100%
                dflex(row, center, flex-start)

                .buttons__language {
                    display flex
                    flex-wrap wrap
                    margin-bottom 2rem
                    border 1px solid white

                    a {
                        padding 4px 6px
                        color rgba(255, 255, 255, .5)
                        font-size 16px
                        text-transform uppercase
                        cursor pointer
                        background-color black
                        transition .3s background-color
                        border 0
                        text-decoration none

                        &.selected {
                            background-color $primary
                            color white
                        }
                    }
                }
            }
        }
    }

    .poweredby {
        color #4B4B4B
        font-weight 500
        justify-self flex-end
        margin-bottom 2rem
        width 100%

        a {
            color inherit
            text-decoration none
        }
    }
}

@media screen and (min-width 2000px) {
    .main {
        .characters {
            max-width unset

            img {
                max-width unset
            }
        }

        .characters__wifi {
            max-width unset

            img {
                max-width unset
            }
        }
    }
}

@media screen and (max-width 576px) {
    .main {
        .main__flex {
            padding 2rem

            .mobile__flex {
                display flex
                z-index 10
            }

            .dculogo {
                max-width 5.5rem
                margin-bottom 2rem
                padding-left 0

                svg {
                    margin-left 0
                    max-height 4rem
                }
            }

            .characters {
                height 100%
                width 100%
                max-width 100%
                z-index 2

                .foni__zsombor {
                    max-height 30rem
                    position absolute
                    right 50%
                    transform translatex(40%)
                    width unset
                    max-width unset
                }
            }

            .characters__wifi {
                height 100%
                width 100%
                max-width 100%
                z-index 3

                .wifi {
                    max-height 12rem
                    position absolute
                    right 50%
                    bottom 8rem
                    transform translatex(40%)
                    width unset
                    max-width unset
                }
            }

            .dplogo {
                width 100%
                padding-left 0
                display flex
                justify-content space-between

                img {
                    max-height 4rem
                    width unset
                }
            }

            .buttons__flex {
                justify-content center
                z-index 5

                .buttons {
                    flex-grow 1
                    align-content flex-end
                    flex-direction row
                    margin 0
                    width 100%
                    max-width unset
                    justify-content space-between
                    padding-bottom 0

                    &>a {
                        width calc(50% - 1rem)
                        order 2
                        margin-bottom 0

                        button {
                            width 100%
                            margin-bottom 0
                            padding 0 .4rem
                            white-space break-spaces
                            height 5rem
                        }
                    }

                    .buttons__episode {
                        margin-bottom 0
                        width 50%
                        order 1

                        .select2 {
                            width 100% !important

                            .select2-selection {
                                height 2.5rem

                                .select2-selection__rendered {
                                    height 2.5rem
                                }
                            }
                        }

                        button {
                            width 100%
                            border-left 1px solid white
                            border-top 1px
                            padding 0 1.25rem
                            height 2.5rem
                        }
                    }

                    .poweredby {
                        order 3
                        width 100%
                        margin-top 2rem
                        text-align center
                        margin-bottom 0
                    }
                }
            }
        }

        .backgradient {
            display block
            position absolute
            bottom 0
            left 0
            right 0
            min-height 20rem
            background linear-gradient(0, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 45%, rgba(47, 47, 45, 0) 100%)
            z-index 4
        }
    }
}

.select2-dropdown {
    max-height 10.5rem
    overflow auto
}

::-webkit-scrollbar {
    width 10px
}

/* Track */
::-webkit-scrollbar-track {
    background transparent
}

/* Handle */
::-webkit-scrollbar-thumb {
    background white
}

@keyframes logoAnim {
    to {
        stroke-dashoffset 0
    }
}