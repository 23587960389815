.navbar {
	background-color $white

	.navbar-toggler {
		.navbar-toggler-icon {
		}
	}

	.navbar-brand {
	}

	.container {
	}

	.navbar-collapse {
		.nav-item {
			.nav-link {
				color $primary
				font-size responsive 14px 18px
				font-range 320px 1200px
			}
		}
	}
}