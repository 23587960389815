.dcuheader{
    width 100%
    position relative
    height 140px
    display flex
    flex-wrap wrap
    justify-content flex-start
    align-items flex-start
    padding 40px 45px

    a{
        height 100%
    }
    
    .logo{
        height 100%
    }

    .logo__dcu{
        margin-right 40px
    }

    .logo__dp{
        margin-right 30px
    }

    i{
        cursor pointer
        font-size 2rem
        color $primary
    }
}

@media screen and (max-width 768px) {
    .dcuheader{
        padding 40px 17px

        .logo__dcu, .logo__dp{
            margin-right 20px
        }

        i{
            margin-left auto
        }
    }
}