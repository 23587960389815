.episode{
    display flex
    width 100%
    height 100%
    min-height 100vh
    position relative
    flex-wrap wrap
    justify-content center
    align-items center

    #frame__next{
        position fixed
        left 50%
        z-index 99
        color rgba(255,255,255,0.3)
        background-color rgba(0,0,0,0.3)
        border 2px solid rgba(255,255,255,0.3)
        width 50px
        height 50px
        display flex
        justify-content center
        align-items center
        border-radius 25px
        transform translate(-50%, -50px)
        cursor pointer
        transition .3s all
        animation animateArrow 2s cubic-bezier(.42,.01,.99,.65) infinite;

        &:hover{
            background-color black
            color white
            border 2px solid rgba(255,255,255,1)
            animation none
        }
    }

    #episode__navigator{
        position fixed
        bottom 3rem
        left 50%
        transform translatex(-49.25%)
        z-index 5
        color white
        display flex
        align-items flex-end
        width 100%
        justify-content center

        &.hidden{
            display none
        }

        a{
            color white
            text-decoration none
            display flex
            flex-wrap wrap
            align-items flex-end

            &.disabled{
                color gray
                pointer-events none
                cursor none
            }

            &:hover{
                .nav{
                    color #f63900
                }
            }

            .nav{
                font-size 2rem
                transition .3s color
                margin 0 1rem
                transform translateY(29%)
            }

        }

        #rewatch{
            display flex
            flex-direction column
            flex-wrap wrap
            align-items center
            cursor pointer
            margin 0 2rem

            &:hover{
                .re{
                    color #f63900
                }
            }

            .re{
                font-size 2rem
                margin 0 1rem .5rem
                animation rotateReArrow 8s infinite
                cursor pointer
                transition .3s color
            }
        }
    }

    #frame__next{
        bottom 0
    }
    
    .placeholder{
        display none
        width 100%
    }

    .pageAfter{
        width 100%
        padding-bottom 30rem
    }

    .pin{
        width 100%
        height 2px
        background-color yellow
        z-index 2
    }

    .logos{
        position fixed
        left 3.5rem
        top 3rem
        max-height 3.75rem
        display flex
        flex-wrap wrap
        justify-content flex-start
        align-items flex-start
        width 100%
        z-index 3

        .logo__background{
            display flex
            flex-wrap nowrap
            justify-content flex-start
            align-items flex-start

            img{
                max-height 3.75rem
                max-width 100%
                width auto
                margin-right 2rem
            }
            i{
                cursor pointer
                font-size 2rem
                color $primary
            }
        }
    }

    .buttons{
        position fixed
        left 0
        bottom 0
        padding 4rem 4rem 2rem 3rem
        display flex
        flex-wrap wrap
        justify-content flex-start
        align-items center
        width 100%
        background linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 25%, rgba(47,47,45,0) 90%);

        >div{
            width 100%
            display flex
            justify-content space-between

            .hidden{
                display none
            }

            button{
                padding 1rem
                font-weight 500
            }

        }
        .poweredby{
            width 100%
            margin-top 2rem
            color #4B4B4B
            font-weight 500

            a{
                color inherit
                text-decoration none
            }
        }
    }

    #picker__toggle{
        position fixed
        right 0
        top 50%
        transform rotate(-90deg) translateY(40px)
        width 110px
        background-color rgba(0,0,0,0.5);
        border 1px solid rgba(255,255,255,0.5);
        color rgba(255,255,255,0.5);
        text-align center
        border-bottom none
        height 2rem
        display flex
        justify-content space-between
        align-items center
        padding 0 1rem
        cursor pointer
        backdrop-filter blur(4px)

        i{
            margin-left 10px
        }
    }

    #picker{
        display none
        position fixed
        right 0
        top 50%
        transform translateY(-50%)
        width 9rem
        background-color rgba(0,0,0,0.5);
        border 1px solid white
        padding 2rem
        color white
        text-align center
        border-right none
        backdrop-filter blur(4px)

        .close{
            position absolute
            top 1rem
            right 1rem

            i{
                display block
                font-size 1.5rem
                cursor pointer
                transition .3s color

                &:hover{
                    color #f63900
                }
            }
        }

        i{
            display none
        }

        .picker__carousel{
            a{
                color white
                text-decoration none

                &.active{
                    color #f63900
                    pointer-events none
                    cursor none
                }

                .item{
                    margin 1.5rem 0
                }
            }
        }
    }

    .frames{
        padding 1rem
        width 100%
        max-width calc(1720px + 2rem)
        display flex
        justify-content flex-start
        align-items flex-start
        flex-wrap wrap
        position relative
        top 15vh

        .trigger{
            width 100%
            height 5px
            margin 1rem 0
            background-color green
        }

        .row{
            width 100%
            display flex
            flex-wrap wrap
            justify-content flex-start
            align-items flex-start
            margin-bottom 1rem

            .frame{
                opacity 0
                height 0
                display flex
                justify-content center
                overflow hidden
                padding-bottom 34%
                background-position center center
                background-size cover
                background-repeat norepeat
                position relative

                &.f1-1{
                    max-width 100%
                    width 100%
                }

                &.f3-1{
                    max-width calc(100% / 3 - 1rem)
                    width 100%
                    padding-bottom 33%

                    &:first-of-type{
                        margin-right 1.5rem
                    }

                    &:last-of-type{
                        margin-left: 1.5rem
                    }
                }

                &.f3-2{
                    padding-bottom 33%

                    &.f0{
                        max-width calc((100% / 3) * 1 - .5rem)
                        width 100%

                        &.f3-2_reverse{
                            max-width calc((100% / 3) * 2 - .5rem)
                        }
                    }

                    &.f1{
                        max-width calc((100% / 3) * 2 - .5rem)
                        width 100%

                        &.f3-2_reverse{
                            max-width calc((100% / 3) * 1 - .5rem)
                        }
                    }

                    &:last-child{
                        margin-left 1rem
                    }
                }

                &.f2-2{
                    padding-bottom 33%
                    max-width calc(100% / 2 - 1rem)
                    width 100%

                    &:first-of-type{
                        margin-right 1rem
                    }

                    &:last-of-type{
                        margin-left: 1rem
                    }
                }

                iframe{
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    border 0
                    pointer-events none
                    object-fit fill
                }

                img{
                    width 100%
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .episode{
        .frames{
            top 25vh
        }
    }
}

@media screen and (max-width: 576px) {
    .episode{
        padding-bottom .5rem

        #episode__navigator{
            bottom 4rem
            transform translatex(-50%)
        }
        
        .background {
            transform scale(4.5)   
        }

        .pageAfter{
            padding-bottom 25rem
        }

        .logos{
            top 0
            left 0
            padding 1rem 1rem 7rem 1rem
            width 100%
            background linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4990371148459384) 50%, rgba(0,0,0,1) 100%)

            .logo__background{
                width 100%

                img{
                    margin-right .5rem
                }

                a{
                    margin-left auto
                    button{
                        padding 1rem .5rem
                        margin 0 !important
                    }
                }
                i{
                    margin-left auto
                }
            }
        }

        .frames{
            padding .5rem
            top 40vh

            .row{
                margin-bottom 0

                .frame{
                    padding-bottom 33%
                    margin-bottom 1rem

                    &.f1-1{
                        margin-bottom .75rem !important
                    }

                    &.f3-1{
                        max-width 100%
                        padding-bottom 100%
                        margin 0 0 1rem 0 !important
                    }

                    &.f3-2{

                        &.f0{
                            margin-left 0
                            max-width 100%
                            padding-bottom 100%

                            &.f3-2_reverse{
                                margin-left 0
                                max-width 100%
                                padding-bottom 50%
                            }
                        }

                        &.f1{
                            max-width 100%
                            padding-bottom 50%
                            margin-left 0 !important

                            &.f3-2_reverse{
                                margin-left 0
                                max-width 100%
                                padding-bottom 100%
                            }
                        }
                    }

                    &.f2-2{
                        max-width 100%
                        padding-bottom 66.66%
                        margin 0 0 1rem 0 !important
                    }
                }
            }
        }

        .buttons{
            width 100%
            display flex
            justify-content center
            align-items center
            padding 0 0 1rem
            left 0
            bottom 0
            background linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4990371148459384) 50%, rgba(255,255,255,0) 100%)
            padding-top 7rem

            >a{
                display none
            }

            .poweredby{
                width 100%
                text-align center
                padding 0 1rem
            }
        }
    }
}

@keyframes rotateReArrow {
    0% {
        transform rotate(0)
    }
    25%{
        transform rotate(-360deg)
    }
    50%{
        transform rotate(-360deg)
    }
    75%{
        transform rotate(-360deg)
    }
    100%{
        transform rotate(-360deg)
    }
}

@keyframes animateArrow {
    0% {
        color rgba(255,255,255,0.3)
        background-color rgba(0,0,0,0.3)
        border 2px solid rgba(255,255,255,0.3)
    }

    50%{
        color rgba(255,255,255,1)
        background-color rgba(0,0,0,1)
        border 2px solid rgba(255,255,255,1)
    }

    100{
        color rgba(255,255,255,0.3)
        background-color rgba(0,0,0,0.3)
        border 2px solid rgba(255,255,255,0.3)
    }
}