#episodeSelect{
	display none
}
.selection {
	.select2-selection{
		background-color transparent
		border	1px solid white
		border-radius 0
		height 3rem

		.select2-selection__rendered{
			color white
			height 3rem
			display flex
			align-items center
		}
		
		.select2-selection__arrow{
			color white
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: .5rem;
			height 3rem
		}

	}
}

.select2-container--default.select2-container--disabled {

	.select2-selection__arrow{
		display none
	}
	.select2-selection--single{
		background-color transparent
	}
}

.select2-container{
	.select2-dropdown{
		background-color rgba(0,0,0,0.8)
		border none
		border-radius 0

		.select2-search{
			display none
		}

		.select2-results__options{
			max-height 10.5rem

			.select2-results__option{
				background-color transparent
				color white
				border: 1px solid white
				border-bottom none
				border-top none
				border-radius 0
				transition .3s all
				height 3.5rem
				display flex
				align-items center
				padding-left 1rem
				backdrop-filter blur(2px)

				&:first-of-type{
					border-top 1px solid white
				}

				&:hover{
					background-color black
				}
			}	
		}
	}
}

@media screen and (max-width: 576px) {
	.selection {
		.select2-selection{
			height 1.75rem

			.select2-selection__rendered{
				height 1.75rem
			}
			
			.select2-selection__arrow{
				height 1.75rem
			}
		}
	}
}