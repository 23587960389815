.singleHero{
    position relative
    width 100%

    .background__spheres{
        position fixed
        top 50%
        left 50%
        width 100%
        height 100%
        transform translate(-50%, -50%)
        max-width 1400px
        background-image url('../img/bg_spheres.png')
        background-position center center
        background-size contain
        background-repeat no-repeat
        z-index -1
    }

    .heroFlex{
        width 100%
        height 100%
        position relative
        display flex
        flex-wrap wrap
        justify-content flex-start
        align-items stretch
        margin-bottom 2rem

        .hero__info{
            width 50%
            display flex
            flex-direction column
            justify-content flex-start
            align-items flex-start
            padding 40px 0 0 175px
            position relative
        
            h1{
                font-weight 900
                color $primary
                font-size 96px
                line-height 115px
                margin-bottom 8px
            }

            h2{
                font-weight 400
                font-size 36px
                line-height 42px
                color white
                margin-bottom 42px
            }

            p{
                max-width 610px
                text-align left
                color white
                font-weight 400
                font-size 18px
                line-height 28px
                margin-bottom 50px
                max-height 150px
                overflow auto
                padding-right 6px
            }

            ul{
                list-style none
                margin-bottom 15px

                li{
                    position relative
                    display flex
                    justify-content flex-start
                    align-items center
                    margin-bottom 35px

                    i{
                        margin-right 36px
                        color $primary
                        font-size 1.5rem
                        width 20px
                    }

                    span{
                        color white
                        font-size 18px
                        line-height 28px
                        font-weight 900
                    }
                }
            }

            .buttons{
                display flex

                a{
                    margin-right 20px
                    text-decoration none
                }
            }
        }

        .hero__image{
            width 50%
            display flex
            flex-direction column
            position relative
            padding 30px 175px 100px 0
            background-position center center
            background-repeat no-repeat
            background-size contain
        }
    }
}

@media screen and (max-width 1200px) {
    .singleHero{
        .heroFlex{
            .hero__info{
                padding 16px 0 0 45px

                h1{
                    font-size 64px
                    line-height 80px
                }

                h2{
                    font-size 24px
                    margin-bottom 20px
                }
            }

            .hero__image{
                padding 16px 45px 0 0
            }
        }
    }
}

@media screen and (max-width 992px) {
    .singleHero{
        .heroFlex{
            .hero__info{
                width 100%
                order 2
                padding-bottom 5rem
            }

            .hero__image{
                width 100%
                order 1
                height 25rem
            }
        }
    }
}

@media screen and (max-width 768px) {
    .singleHero{
        .heroFlex{
            .hero__info{
                padding 0
                margin 16px 16px 0
                p{
                    width 100%
                    max-width unset
                }
            }

            .hero__image{
                width 100%
                order 1
                height 25rem
            }

            .buttons{
                margin-bottom 5rem
                width 100%
                justify-content space-between
            }
        }
    }
}

@media screen and (max-width 576px) {
    .singleHero{
        .heroFlex{
            .buttons{
                flex-direction column
                justify-content center
                align-items center

                .btn{
                    margin-bottom 16px
                }
            }
        }
    }
}